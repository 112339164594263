import React from 'react'
import { graphql } from 'gatsby'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'src/components/Layout'
import { useSearchParamsFromQueryVariables } from '@vtex/gatsby-theme-store'
import type { SelectedFacets } from '@vtex/gatsby-theme-store/src/sdk/search/converter/useSearchParamsFromQueryVariables'
import CollectionView from 'src/views/collection'
import { EventProductClickSetListParamProvider } from 'src/contexts/EventProductClickSetListParamContext'

import type {
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables,
} from './__generated__/CollectionPageQuery.graphql'

export type StoreCollectionPageProps = PageProps<
  CollectionPageQueryQuery,
  CollectionPageQueryQueryVariables & { slug: string }
>

const Page: FC<StoreCollectionPageProps> = (props) => {
  const { pageContext, data } = props

  const canonicalMetatag = `https://www.avon.com.br/${props.location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace(/\/$/, '')

  const searchParams = useSearchParamsFromQueryVariables({
    itemsPerPage: 12,
    selectedFacets: pageContext.selectedFacets as SelectedFacets[],
    sort: '',
  })

  return (
    <EventProductClickSetListParamProvider
      categoryShelfTitle={props.data?.cmsPlp?.sections[0]?.data?.title}
    >
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <CollectionView
          data={data}
          searchParams={searchParams}
          pageInfo={{ size: 12 }}
          params={{ slug: pageContext.slug }}
        />
      </Layout>
    </EventProductClickSetListParamProvider>
  )
}

export const query = graphql`
  query CollectionPageQuery(
    $id: String!
    $collectionId: String!
    $selectedFacets: [VTEX_SelectedFacetInput!]!
  ) {
    cmsSeo {
      seo {
        siteMetadata {
          titleTemplate
          title
          description
        }
      }
    }

    storeCollection(id: { eq: $id }) {
      seo {
        title
        description
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }

    cmsPlp(parameters: { collection: { categoryId: { eq: $collectionId } } }) {
      sections {
        name
        data
      }
    }

    vtex {
      productSearch(
        from: 0
        to: 11
        selectedFacets: $selectedFacets
        hideUnavailableItems: false
        simulationBehavior: skip
      ) {
        products {
          ...ProductSummary_product
        }
        recordsFiltered
      }
      facets(
        selectedFacets: $selectedFacets
        operator: or
        behavior: "Static"
        removeHiddenFacets: true
      ) {
        breadcrumb {
          href
          name
        }
        facets {
          name
          type
          values {
            key
            name
            value
            selected
            quantity
            range {
              from
              to
            }
          }
        }
      }
    }
  }
`

export default Page
